import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from './pages/main_page';


const App = () => {
  return (<div className='bg-gray-100 min-h-screen flex flex-col'>
    <header className='bg-blue-500 text-white text-left p-4'>
      <p className='text-xl'>
        Відомості про себе
      </p>
    </header>
    <div className='container mx-auto p-4 max-w-3xl'>
      <Routes>
        <Route path="/" element={<MainPage />} />
      </Routes>
    </div>

    <footer className='bg-blue-500 text-white text-left p-4 mt-auto'>
      <p>
        © 2024
      </p>
    </footer>
  </div>);
};

export default App;
