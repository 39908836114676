import React, { useState } from 'react';

function LatinInput({ label, value, setValue, placeholder }) {
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);

  const validate = (value) => {
    const regex = /^[A-Za-z]+(\s+[A-Za-z]+)+$/;
    return regex.test(value);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (isTouched) {
      setIsValid(validate(newValue));
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
    setIsValid(validate(value));
  };

  return (
    <div>
      <label className="block">{label}</label>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        required
        className={`border-2 p-2 rounded-md w-full ${!isValid && isTouched ? 'border-red-500' : 'border-gray-300'}`}
      />
      {!isValid && isTouched && (
        <div className="text-red-500 text-sm mt-1">
          Введіть два або більше слів латиницею, розділених пробілами
        </div>
      )}
    </div>
  );
}

export default LatinInput;