import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip'

function EmailInput({ label, value, setValue, placeholder }) {
  const [isValid, setIsValid] = useState(true);
  const [isTouched, setIsTouched] = useState(false);

  const domains = [
    "carat.kiev.ua",
    "carat.kyiv.ua",
    "mediamaker.com.ua",
    "dxukraine.com",
    "iprospect.com.ua",
    "isobar.com.ua",
    "posterscope.com.ua",
    "dentsu.com.ua",
    "storylab.com.ua",
    "umediagroup.com.ua",
    "outdoor-media.com.ua",
    "dentsumedia.com.ua",
    "dentsuaegis.com.ua"
  ];

  const validate = (email) => {
    const domainRegex = domains.join('|').replace(/\./g, '\\.');
    const regex = new RegExp(`^[A-Za-z]+\\.[A-Za-z]+@(${domainRegex})$`);
    return regex.test(email);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (isTouched) {
      setIsValid(validate(newValue));
    }
  };

  const handleBlur = () => {
    setIsTouched(true);
    setIsValid(validate(value));
  };

  return (
    <div>
      <label className="flex items-center gap-2">{label}    <a className="my-anchor-element text-blue-500">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
        </svg>
      </a></label>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        required
        className={`border-2 p-2 rounded-md w-full ${!isValid && isTouched ? 'border-red-500' : 'border-gray-300'}`}
      />
      <Tooltip anchorSelect=".my-anchor-element" place="right">
        Список корпоративних доменів:
        <ul>
          {domains.map((domain) => (
            <li key={domain}>{domain}</li>
          ))}
        </ul>
      </Tooltip>
      {!isValid && isTouched && (
        <div className="text-red-500 text-sm mt-1">
          Будь ласка, введіть емейл у форматі Firstname.Lastname@domain.ua, де domain.ua є одним з корпоративних доменів.
        </div>
      )}
    </div>
  );
}

export default EmailInput;