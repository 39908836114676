import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { transliterate } from 'transliteration';
import InputMask from 'react-input-mask';
import CyrillicInput from "./components/CyrillicInput";
import LatinInput from "./components/LatinInput";
import EmailInput from "./components/EmailInput";
import { Tooltip } from 'react-tooltip'

const MainPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [file, setFile] = useState(null);
    const [about, setAbout] = useState('');
    const [fileError, setFileError] = useState('');
    const [transliteratedName, setTransliteratedName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [agency, setAgency] = useState('');

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/png'];
        if (!validTypes.includes(file.type)) {
            setFileError('Тільки JPEG/PNG формати доступні дял завантаження.');
            return;
        }
        if (file.size / 1024 / 1024 > 20) { // 5MB max
            setFileError('Файл не повинен перевищувати 5MB.');
            return;
        }

        const URL = window.URL || window.webkitURL;
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = () => {
            URL.revokeObjectURL(objectUrl);
            if (img.width < 600 || img.height < 600 || img.width > 5000 || img.height > 5000) {
                setFileError('Ширина і висота мають бути у межах 600-5000.');
            } else {
                setFileError('');
                setFile(file);
            }
        };
        img.src = objectUrl;
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fileError) {
            alert("Помилка валідації файлу");
            return;
        }
        // Здесь будет код для отправки формы
        console.log({ name, email, phone, file })

        // API /api/user_profile
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('phone', phone);
            formData.append('photo', file);
            formData.append('about', about);
            formData.append('nameLat', transliteratedName);
            formData.append('birthday', birthday);
            formData.append('agency', agency);

            const response = await fetch('/api/user_profile', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
            console.log(data);
            alert('Дякуємо, дані збережені! Копія даних надіслана на вказану вами E-Mail адресу.');

        } catch (error) {
            console.error('Ошибка:', error);
            alert('Помилка відправки форми');

        }
    };

    const handleGetUsers = async () => {
        try {
            const response = await fetch('/api/user_profile');
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }

    useEffect(() => {
        handleGetUsers();
    }, []);
    return (<>
        {/* <button onClick={handleGetUsersPhotos} className="bg-blue-500 text-white p-2 rounded-md">Завантажити фото</button> */}
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 max-w-md mx-auto mt-8">
            <div>
                <CyrillicInput
                    label="Ім'я і прізвище"
                    value={name}
                    setValue={setName}
                    placeholder="Ім'я і прізвище"
                />
            </div>
            <div>
                <LatinInput
                    label="Імʼя і прізвище як в закордонному паспорті"
                    value={transliteratedName}
                    placeholder="Імʼя і прізвище як в закордонному паспорті"
                    setValue={setTransliteratedName}
                />
            </div>
            {/* Дата народження */}
            <div>
                <label className="block">Дата народження</label>
                <input
                    value={birthday}
                    onChange={(e) => setBirthday(e.target.value)}
                    type="date"
                    className="border-2 border-gray-300 p-2 rounded-md w-full"
                />
            </div>
            <div>
                <EmailInput
                    label="Робочий Email"
                    value={email}
                    setValue={setEmail}
                    placeholder="Робочий Email"
                />
            </div>
            <div>
                <label className="block">Номер мобільного телефона</label>
                <InputMask mask="+380 99 999 9999" value={phone} onChange={(e) => setPhone(e.target.value)}>
                    {(inputProps) => <input
                        {...inputProps}
                        type="tel"
                        placeholder="Номер мобільного телефона"

                        className="border-2 border-gray-300 p-2 rounded-md w-full"
                    />}
                </InputMask>
            </div>
            {/* Агенція */}
            <div>
                <label className="block">Агенція</label>
                <select
                    type="text"
                    value={agency}
                    onChange={(e) => setAgency(e.target.value)}
                    name="agency"
                    placeholder="Агенція"
                    className="border-2 border-gray-300 p-2 rounded-md w-full"
                >
                    <option value="">Оберіть агенцію</option>
                    <option value="Dentsu Ukraine">Dentsu Ukraine</option>
                    <option value="Carat Ukraine">Carat Ukraine</option>
                    <option value="dentsu X Ukraine">dentsu X Ukraine</option>
                    <option value="iProspect Ukraine">iProspect Ukraine</option>
                    <option value="Isobar Ukraine">Isobar Ukraine</option>
                    <option value="Posterscope Ukraine">Posterscope Ukraine</option>
                    <option value="Amplifi Ukraine">Amplifi Ukraine</option>
                    <option value="The Story Lab Ukraine">The Story Lab Ukraine</option>
                    <option value="UMG">UMG</option>
                    <option value="Media Maker">Media Maker</option>
                    <option value="Outdoor Media">Outdoor Media</option>
                    <option value="Merkle">Merkle</option>
                </select>
            </div>
            {/* Про себе */}
            <div>
                <label className="block">Про себе</label>
                <textarea
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                    placeholder="Про себе (Ваші хобі, досвід, експертиза, роки роботи в компанії чи щось іще, що колегам може бути цікаво про вас дізнатись."
                    className="border-2 border-gray-300 p-2 rounded-md w-full min-h-[100px]"
                />
            </div>
            <div>
                <label className="flex items-center gap-2">Ваше фото  <a className="photo-anchor text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                    </svg>
                </a></label>
                <Tooltip anchorSelect=".photo-anchor" place="right">
                    <div className="text-sm p-2 rounded-md shadow-md">
                        <p>Додайте фото, щоб ваші колеги могли вас впізнати.</p>
                        <p>Ваше фото портретного формату й у гарній якості.</p>
                        <p>Фото має бути висотою та шириною не менше 600px і не більше 5000px.</p>
                        <p>Формати: JPEG, PNG.</p>
                        <p>Розмір файлу не більше 5MB.</p>
                    </div>
                </Tooltip>
                <input
                    className="border-2 border-gray-300 p-2 rounded-md w-full"
                    type="file"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                            validateFile(file);
                        }
                    }}
                />
                {file && (
                    <img src={
                        URL.createObjectURL(file)
                    } alt="Preview" className="mt-4 w-32 h-32 object-cover" />
                )}
                {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
            </div>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded-md">
                Відправити</button>
        </form>
    </>);
}

export default MainPage;